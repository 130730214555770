import React from "react";

const PrivacyPolicy = () => {
	return (
		<>
			<div className="privacy-container">
				<h1>Privacy Policy</h1>
				<h2>Website Visitors</h2>
				<p>
					Like most website operators, this site collects
					non-personally-identifying information of the sort that web
					browsers and servers typically make available, such as the
					browser type, language preference, referring site, and the
					date and time of each visitor request. Our purpose in
					collecting non-personally identifying information is to
					better understand how our visitors use the website. From
					time to time, we may share non-personally-identifying
					information in the aggregate with our partners for the
					purposes of improving the service we provide. This site also
					collects potentially personally-identifying information like
					Internet Protocol (IP) addresses. We do not use such
					information to identify our visitors, however, and do not
					disclose such information, other than under the same
					circumstances that we use and disclose
					personally-identifying information, as described below.
				</p>
				<h2>Cookies</h2>
				<p>
					A cookie is a string of information that a website stores on
					a visitor’s computer, and that the visitor’s browser
					provides to the website each time the visitor returns. This
					site uses cookies to help us study our visitors’ usage of
					the site, and potentially keep track of visitors’ website
					access preferences. Visitors who do not wish to have cookies
					placed on their computers should set their browsers to
					refuse cookies before using our site, with the drawback that
					certain features of the site may not function properly
					without the aid of cookies.
				</p>
				<h2>Privacy Policy Changes</h2>
				<p>
					Although most changes are likely to be minor, we may change
					our Privacy Policy from time to time, and at our sole
					discretion. We encourage visitors to frequently check this
					page for any changes to our Privacy Policy. Your continued
					use of this site after any change in this Privacy Policy
					will constitute your acceptance of such change.
				</p>
			</div>
		</>
	);
};

export default PrivacyPolicy;
